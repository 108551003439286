import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const BadgeWrapper = styled.div`
    max-width: 50%;
    margin-top: 25px;
    background: transparent;
    display: grid;
    margin-left: 30px;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr;
    align-items: center;
    grid-gap: 16px;
    padding: 0 auto;
`;

export const InfoContainer = styled.div`
    color: #fff;
    background: rgb(192,179,210);
background: linear-gradient(0deg, rgba(192,179,210,1) 0%, rgba(238,237,243,0.8785889355742297) 15%, rgba(238,237,243,1) 100%);
    @media screen and (max-width: 768px) {
        padding: 100px 0;
    }
`;

export const BadgeItem = styled(Link)`
    background: #eeedf3;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    height: 50px;
    width: 50px;
    border: 1px solid #60596b;
    margin: 0 0;
    overflow: hidden;
    box-shadow: 0 1px 3px rgba(0,0,0,0.2);
    transition: all 0.2s ease-in-out;
    color: #60596b;

    &:hover {
        transform: scale(1.05);
        transition: all 0.2s ease-in-out;
        cursor: pointer;
        background: #60596b;
        color: #eeedf3;
    }

`;
export const BtnWrap = styled.div`
    display: flex;
    justify-content: flex-start;
`;

export const TopLine = styled.p`
    color: #0d0d0d;
    font-size: 16px;
    line-height: 16px;
    font-weight: 700;
    letter-spacing: 1.4px;
    text-transform: uppercase;
    margin-bottom: 16px;

    @media screen and (max-width: 768px) {
        font-size: 14px;
    }

    @media screen and (max-width: 480px) {
        font-size: 12px;
    }
`;

export const Heading = styled.h1`
    margin-bottom: 24px;
    font-size: 48px;
    line-height: 1.1;
    font-weight: 600;
    color: ${({lightText}) => (lightText ? '#fff' : '#0d0d0d')};

    @media screen and (max-width: 768px) {
        font-size: 40px;
    }

    @media screen and (max-width: 480px) {
        font-size: 1.5rem;
    }
`;

export const Subtitle = styled.p`
    max-width: 440px;
    margin-bottom: 25px;
    font-size: 18px;
    line-height: 30px;
    color: ${({darkText}) => (darkText ? '#0d0d0d' : '#fff')};
`;

export const ResumeButton = styled(Link)`
    border-radius: 3.5px;
    background: #60596b;
    white-space: nowrap;
    color: #fff;
    padding: 14px 48px;
    font-size: 20px;
    margin-top: 35px;
    margin-left: 30px;
    outline: none;
    border: 1px solid #60596;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;

    text-decoration: none;
    transition: all 0.2s ease-in-out;

    &:hover {
        transform: scale(1.05);
        transition: all 0.2s ease-in-out;
        cursor: pointer;
    }
`;
