import portfolioimg from '../../images/about-me-img.svg';
import abtmeimg from '../../images/portfolio-img.svg';
import contactmeimg from '../../images/contact-img.svg';

export const homeObjOne = {
    id: 'about',
    lightBg: true,
    lightText: false,
    lightTextDesc: true,
    topLine: 'About Me',
    headline: 'Colin Ruan',
    description: 'I am an app developer who is passionate about building fast, responsive, and easy to use Android apps.',
    buttonLabel: 'My Projects',
    imgStart: false,
    img: abtmeimg,
    alt: 'Uwu',
    dark: true,
    primary: false,
    darkText: true
};

export const homeObjTwo = {
    id: 'portfolio',
    lightBg: false,
    lightText: true,
    lightTextDesc: true,
    topLine: 'Software Projects',
    headline: '',
    description: '',
    buttonLabel: 'GitHub',
    imgStart: false,
    img: portfolioimg,
    alt: 'Uwu',
    dark: false,
    primary: false,
    darkText: true
};

export const homeObjThree = {
    id: 'contact',
    lightBg: true,
    lightText: false,
    lightTextDesc: true,
    topLine: 'Contact Me',
    headline: 'colinruan78@gmail.com',
    description: '',
    buttonLabel: '',
    imgStart: true,
    img: contactmeimg,
    alt: '',
    dark: false,
    primary: false,
    darkText: false
};