import React from 'react';
import { Button, ButtonR } from '../ButtonElement';
import {AiOutlineLinkedin} from 'react-icons/ai';
import contactmeimg from '../../images/contact-img.svg';
import {VscGithub} from 'react-icons/vsc';
import {SiGoogleplay} from 'react-icons/si';
import pdf from '../../images/COLIN_RUAN_-_Resume.pdf';

import {InfoWrapper, InfoRow, Column1, Column2, TextWrapper,
     BtnWrap, Img, ImgWrap } from '../InfoSection/InfoElements';
import { InfoContainer, BadgeItem, BadgeWrapper, ResumeButton, TopLine, Heading, Subtitle } from './ContactsElements';

const ContactSection = ({lightBg, id, imgStart, topLine, lightText,
    headline, darkText, description,
     buttonLabel, img, alt,
   primary, dark, dark2}) => {
    return (
        <InfoContainer lightBg={lightBg} id={id}>
            <InfoWrapper>
                <InfoRow imgStart={imgStart}>
                    <Column1>
                        <TextWrapper>
                            <TopLine>{topLine}</TopLine>
                            <Heading lightText={lightText}>{headline}</Heading>
                            <Subtitle darkText={darkText}>{description}</Subtitle>
                            <BadgeWrapper>
                                <BadgeItem to={{pathname: "https://play.google.com/store/apps/developer?id=crosie"}} target="_blank">
                            
                                        <SiGoogleplay size={30}/>
                         
                                </BadgeItem>
                                <BadgeItem to={{pathname: "https://github.com/niloc78"}} target="_blank">
                               
                                        <VscGithub size={30}/>
                                 
                                </BadgeItem>
                                <BadgeItem to={{pathname: "https://www.linkedin.com/in/colinruan"}} target="_blank">
                         
                                        <AiOutlineLinkedin size={30}/>
                                </BadgeItem>
                            </BadgeWrapper>
                            <BtnWrap>
                                <ResumeButton to={pdf} target="_blank">Download Resume</ResumeButton>
                            </BtnWrap>
                        </TextWrapper>
                    </Column1>

                    <Column2>
                        <ImgWrap>
                            <Img src={img} alt={alt}/>
                        </ImgWrap>
                    </Column2>

        

                </InfoRow>
            </InfoWrapper>
        </InfoContainer> 
    )
}

export default ContactSection;
