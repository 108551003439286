import React from 'react';
import { Button } from '../ButtonElement';
import { InfoContainer, InfoWrapper, InfoRow, Column1, Column2, TextWrapper, TopLine, 
    Heading, Subtitle, BtnWrap, Img, ImgWrap, MidLine, IconWrapper } from './InfoElements';
import {SiJava, SiKotlin, SiPython, SiJavascript} from 'react-icons/si';

const InfoSection = ({lightBg, id, imgStart, topLine, lightText,
     headline, darkText, description,
      buttonLabel, img, alt,
    primary, dark, dark2}) => {
    return (
        <>
        <InfoContainer lightBg={lightBg} id={id}>
            <InfoWrapper>
                <InfoRow imgStart={imgStart}>
                    <Column1>
                        <TextWrapper>
                            <TopLine>{topLine}</TopLine>
                            <Heading lightText={lightText}>{headline}</Heading>
                            <Subtitle darkText={darkText}>{description}</Subtitle>
                            <Subtitle darkText={darkText}>You can always find me in the middle of building something new or learning new programming languages and frameworks.</Subtitle>
                            
                            <BtnWrap>
                                <Button to="portfolio"
                                smooth={true}
                                duration={500}
                                spy={true}
                                exact="true"
                                offset={-80}
                                primary={primary ? 1 : 0}
                                dark={dark ? 1 : 0}
                                dark2={dark2 ? 1 : 0}
                                >{buttonLabel}</Button>
                            </BtnWrap>

                            <MidLine>Languages</MidLine>

                            <IconWrapper>
                                <SiJava size={30}/>
                                <SiKotlin size={30}/>
                                <SiPython size={30}/>
                                <SiJavascript size={30}/>
                            </IconWrapper>

                            <MidLine>Frameworks/Libraries</MidLine>

                            <Subtitle darkText={1}>Android Native, Dagger Hilt, Retrofit2, Android Volley, React.js, Picasso, Room, RxJava3, RxKotlin/RxAndroid, Kotlin Coroutines</Subtitle>

                        </TextWrapper>
                    </Column1>

                    <Column2>
                        <ImgWrap>
                            <Img src={img} alt={alt}/>
                        </ImgWrap>
                    </Column2>

                </InfoRow>
            </InfoWrapper>
        </InfoContainer> 
        </>
    );
};

export default InfoSection;
