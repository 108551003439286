import styled from 'styled-components';
import {Link as LinkR} from 'react-router-dom';
import {Link as LinkS} from 'react-scroll';

export const Nav = styled.nav`
    background: #2F3038;
    height: 80px;
    margin-top: -80px; 
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
    position: sticky;
    top: 0;
    z-index: 10;

    @media screen and (max-width: 960px) {
        transition: 0.8s all ease;
    }
`;

export const NavbarContainer = styled.div`
    display: flex;
    justify-content: flex-start;
    height: 80px;
    z-index: 1;
    width: 100%;
    margin-right: 35%;
    padding: 0 23px;
    max-width: 1100px;
    @media screen and (max-width: 1000px) {
        margin-right: 25%;
    }
`;
export const NavLogo = styled(LinkR)`

    color: #fff;
    justify-self: flex-start;
    cursor: pointer;
    font-size: 1.5rem;
    display: flex;
    align-items: center;
    margin-left: 24px;
    font-weight: bold;
    text-decoration: none;

`;

export const MobileIcon = styled.div`
    display: none;

    @media screen and (max-width: 768px) {
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(-100%, 60%);
        font-size: 1.8rem;
        cursor: pointer;
        color: #fff;
    }
`;

export const NavMenu = styled.ul`
    display: flex;
    align-items: center;
    list-style: none;
    text-align: center;
    margin-right: 0px;
    


    @media screen and (max-width: 768px) {
        display: none;
    }

`;

export const NavItem = styled.li`
    height: 80px;
`;

export const NavLinks = styled(LinkS)`
    color: #fff;
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 0 4rem;
    height: 100%;
    cursor: pointer;

    &.active {
        border-bottom: 3px solid #fff;
    }
`;

export const NavBtn = styled.nav`
    background-color: #000;
    color: #fff;
    padding: 8px 20px;
    border: 1px solid #fff;
    transition: all 0.3s ease-out;

    padding: 8px 20px;
    border-radius: 2px;
    outline: none;
    border: none;
    cursor: pointer;

    @media screen and (max-width: 768px) {
        display: none;
    }
`;

export const NavBtnLink = styled(LinkR)`
    background: #fff;
    white-space: nowrap;
    padding: 10px 22px;
    color: #fff;
    font-size: 16px;
    outline: none;
    border: 1px solid #fff;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    text-decoration: none;

    &:hover {
        background: #fff;
        color: #242424;
        transition: all 0.3s ease-out;
    }
`;

