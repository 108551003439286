import React from 'react';
import {Nav, NavbarContainer,
     NavLogo, MobileIcon, NavMenu, NavItem,
      NavLinks, NavBtn, NavBtnLink} from './NavbarElements';
import {FaBars} from 'react-icons/fa';
const Navbar = ({toggle}) => {
    return (
      <>
        <Nav>
            <NavbarContainer>
                <NavLogo to="/">
                </NavLogo>
                <MobileIcon onClick={toggle}>
                    <FaBars/>
                </MobileIcon>

                <NavMenu>

                    <NavItem>
                        <NavLinks to="home"
                        smooth={true}
                        duration={500}
                        spy={true}
                        exact="true"
                        offset={-80}
                        >Home</NavLinks>
                    </NavItem>

                    <NavItem>
                        <NavLinks to="about"
                        smooth={true}
                        duration={500}
                        spy={true}
                        exact="true"
                        offset={-80}
                        >About</NavLinks>
                    </NavItem>

                    <NavItem>
                        <NavLinks to="portfolio"
                        smooth={true}
                        duration={500}
                        spy={true}
                        exact="true"
                        offset={-80}
                        >Portfolio</NavLinks>
                    </NavItem>

                    <NavItem>
                        <NavLinks to="contact"
                        smooth={true}
                        duration={500}
                        spy={true}
                        exact="true"
                        offset={-80}
                        >Contact</NavLinks>
                    </NavItem>
                </NavMenu>

                {/* <NavBtn>
                    <NavBtnLink to="/about">View My Work</NavBtnLink>
                </NavBtn> */}

            </NavbarContainer>
        </Nav>
      </> 
    );
};

export default Navbar
