import styled from 'styled-components';
import {Link} from 'react-router-dom';

export const ProjectsContainer = styled.div`
    height: 1000px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #737077;

    @media screen and (max-width: 768px) {
        height: 1100px;
    }

    @media screen and (max-width: 480px) {
        height: 1300px;
    }
`;

export const BadgeItem = styled(Link)`
    max-width: 25%;
    max-height: 25%;
`;

export const BadgeWrapper = styled(Link)`
    width: 100%;
    height: 100%;
    min-width: 50px;
    align-items: center;
    justify-content: center;
    max-width: 100px;
    @media screen and (max-width: 1000px) {
        max-width: 90px;
    }
    @media screen and (max-width: 768px) {
        max-width: 80px;
    }
    @media screen and (max-width: 574px) {
        max-width: 70px;
    }
    @media screen and (max-width: 480px) {
        max-width: 60px;
    }
`

export const BadgeIcon = styled.img`
    width: 100%;
    height: 100%;
    min-width: 50px;
    max-width: 100px;
    
    @media screen and (max-width: 1000px) {
        max-width: 90px;
    }
    @media screen and (max-width: 768px) {
        max-width: 90px;
    }
    @media screen and (max-width: 574px) {
        max-width: 70px;
    }
    @media screen and (max-width: 480px) {
        max-width: 60px;
    }
`;


export const ProjectsWrapper = styled.div`
 
    background: transparent;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    align-items: center;
    grid-gap: 40px;


    @media screen and (max-width: 1000px) {
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr 1fr 1fr;
        grid-gap: 16px;

    
    }

    @media screen and (max-width: 768px) {
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr 1fr 1fr;

        grid-gap: 16px;


    }

    @media screen and (max-width: 480px) {
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr 1fr 1fr;

        grid-gap: 10px;

  
    }
    
`;

export const ProjectsCard = styled(Link)`
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    border-radius: 3px;
    max-height: 200px;
    max-width: 340px;
    border: 2px solid #fff;
    overflow: hidden;
    //box-shadow: 35px 35px 0px rgba(188,166,166,0.2);
    transition: all 0.2s ease-in-out;


    &:hover {
        transform: scale(1.05);
        transition: all 0.2s ease-in-out;
        cursor: pointer;
        box-shadow: 35px 35px 0px rgba(188,166,166,0.5);
    }

    @media screen and (max-width: 1000px) {
        max-width: 250px;
        max-height: 180px;
    }

    @media screen and (max-width: 768px) {
        max-height: 180px;
        max-width: 250px;

    }
    @media screen and (max-width: 574px) {
        max-height: 160px;
        max-width: 247px;

    }
    @media screen and (max-width: 480px) {
        max-height: 100px;
        max-width: 150px;

    }
`;

export const ProjectsCard2 = styled(Link)`
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    border-radius: 3px;
    max-height: 200px;
    max-width: 350px;
    border: 2px solid #fff;
    overflow: hidden;
    //box-shadow: -35px 35px 0px rgba(188,166,166,0.2);
    transition: all 0.2s ease-in-out;


    &:hover {
        transform: scale(1.05);
        transition: all 0.2s ease-in-out;
        box-shadow: -35px 35px 0px rgba(188,166,166,0.5);
        cursor: pointer;
    }

    @media screen and (max-width: 1000px) {
        max-width: 250px;
        max-height: 180px;
    }

    @media screen and (max-width: 768px) {
        max-height: 180px;
        max-width: 250px;

    }
    @media screen and (max-width: 574px) {
        max-height: 160px;
        max-width: 247px;

    }
    @media screen and (max-width: 480px) {
        max-height: 100px;
        max-width: 150px;

    }
`;

export const ProjectsIcon = styled.img`
    height: 100%;
    width: 100%;
    overflow: hidden;
`;

export const ProjectsH1 = styled.h1`
    font-size: 2.5rem;
    color: #fff;
    margin-bottom: 64px;

    @media screen and (max-width: 480px) {
        font-size: 2rem;
    }    
`;

export const ProjectsH2 = styled.h2`
    font-size: 14px;
    text-align: center;
    max-height: 5%;
`;

export const ProjectsP = styled.p`
    font-size: 1px;
    text-align: start;
    padding: 0px 15px 10px 15px;
    line-height: 0px;
    max-height: 15%;
`;


export const InfoContainer = styled.div`
    color: #fff;
    display: flex;
    background: ${({lightBg}) => (lightBg ? '#EEEDF3' : '#ccc7d4')};

    @media screen and (max-width: 768px) {
        padding: 100px 0;
    }
`;
export const ParticleWrapper = styled.div`
    display: flex;
    z-index: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    @media screen and (max-width: 480px) {
        display: none;
    }

`;

export const LinkWrapper = styled.div`

    background: transparent;
    display: flex;

    align-items: start;
    justify-content: start;


`;




export const InfoWrapper = styled.div`
    display: grid;
    z-index: 1;
    height: 1000px;
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    padding: 0 10px;
    justify-content: center;
    @media screen and (max-width: 480px) {
        padding: 0 0px;
    }
`;

export const InfoRow = styled.div`
    width: 100%;
    display: grid;

    align-items: center;
    padding: 0 30px;

    grid-auto-columns: minmax(auto, 1fr);
  

    grid-template-areas: ${({imgStart}) => (imgStart ? `'col2 col1'` : `'col1 col2'` )};

    @media screen and (max-width: 1000px) {
        padding: 0;
        grid-template-areas: ${({imgStart}) => (imgStart ? `'col1' 'col2'` : `'col2 col2' 'col1 col1'`)};
    }

    @media screen and (max-width: 768px) {
        padding: 0;
        grid-template-areas: ${({imgStart}) => (imgStart ? `'col1' 'col2'` : `'col2 col2' 'col1 col1'`)};
    }
`;

export const Column1 = styled.div`
    
    grid-area: col1;
    
 
    @media screen and (max-width: 1000px) {

    }
    @media screen and (max-width: 768px) {

    }

`;

export const Column2 = styled.div`
    margin-bottom: 15px;
    padding: 0 100px;
    grid-area: col2;
    @media screen and (max-width: 1000px) {
        padding: 0 3%;
    }
    @media screen and (max-width: 768px) {
        padding: 0 3%;
    }
   
`;



export const TextWrapper = styled.div`
    display: flex;
    flex-flow: column;
    align-items: start;
    justify-content: center;
    padding-top: 0px;
    padding-bottom: 60px;
    margin-top: 10px;

`;

export const TextWrapper2 = styled.div`
max-width: 700px;
    


@media screen and (max-width: 1000px) {
    max-width: 700px;
    max-height: 200px;
    padding: 20px 0;
}

@media screen and (max-width: 768px) {
    max-width: 700px;
    max-height: 400px;
    padding: 20px 0;
}
@media screen and (max-width: 574px) {
    max-width: 700px;
    max-height: 400px;
    padding: 20px 0;
}
@media screen and (max-width: 480px) {
    max-height: 400px;
    max-width: 200px;
    padding: 10px 0;
}
`;

export const BuiltWith = styled.p`
    color: #9c95a6;
    font-size: 16px;
    line-height: 16px;
    font-weight: 700;
    letter-spacing: 1.4px;
    margin-top: 15px;
    text-transform: uppercase;
    margin-bottom: 16px;

    @media screen and (max-width: 768px) {
        font-size: 14px;
        line-height: 12px;
        width: 300px;
    }

`;

export const TopLine = styled.p`
    color: #0d0d0d;
    font-size: 16px;
    line-height: 16px;
    font-weight: 700;
    letter-spacing: 1.4px;
    text-transform: uppercase;


    @media screen and (max-width: 1000px) {

    }
    @media screen and (max-width: 768px) {

    }


`;

export const TopLine2 = styled.h1`
    color: #BB0067;
    font-size: 0.9rem;
    line-height: 14px;
    font-weight: 700;
    letter-spacing: 1.1px;
    margin-bottom: 5px;
    @media screen and (max-width: 1000px) {
        font-size: 0.71rem;

    }
    @media screen and (max-width: 768px) {
        font-size: 0.71rem;

    }
    @media screen and (max-width: 480px) {
        font-size: 0.5rem;

    }

`;
export const MidLine = styled.h2`
    color: rgba(187, 0, 103, 0.5);
    font-size: 0.75rem;
    line-height: 14px;
    font-weight: 300;
    letter-spacing: 1.1px;
    margin-left: 5px;
    margin-bottom: 5px;
    max-width: 350px;

    @media screen and (max-width: 768px) {

        font-size: 0.65rem;
        line-height: 13px;
        max-width: 300px;
    }
    @media screen and (max-width: 574px) {

        font-size: 0.5rem;
        line-height: 9px;
        max-width: 247px;
    }
    @media screen and (max-width: 480px) {
        max-width: 150px;
        font-size: 5px;
        line-height: 7px;
    }
`;

export const Heading = styled.h1`
    margin-bottom: 24px;
    font-size: 48px;
    line-height: 1.1;
    font-weight: 600;
    color: ${({lightText}) => (lightText ? '#fff' : '#0d0d0d')};

    @media screen and (max-width: 480px) {
        font-size: 32px;
    }
`;

export const Subtitle = styled.p`
    max-width: 440px;
    margin-bottom: 25px;
    font-size: 18px;
    line-height: 24px;
    color: ${({darkText}) => (darkText ? '#0d0d0d' : '#fff')};
`;

export const Subtitle2 = styled.p`
    max-width: 350px;
    width: 100%;
    font-size: 13px;
    line-height: 15px;
    margin-bottom: 5px;
    color: #56535c;

    @media screen and (max-width: 1000px) {
        max-width: 300px;
        font-size: 8px;
        line-height: 10px;
    }

    @media screen and (max-width: 768px) {

        max-width: 300px;
        font-size: 10px;
        line-height: 12px;
    }
    @media screen and (max-width: 574px) {

        max-width: 247px;
        font-size: 10px;
        line-height: 12px;
    }
    @media screen and (max-width: 480px) {

        max-width: 150px;
        font-size: 7px;
        line-height: 9px;
    }
`;
export const BtnWrap = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;


`;

export const ImgWrap = styled.div`
    max-width: 750px;

    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    @media screen and (max-width: 1000px) {
        max-width: 400px;
    }
`;  

export const Img = styled.img`
    width: 100%;
    margin: 0 0 10px 0;
    padding-right: 0;
`;
