import React from 'react';
import { ProjectsContainer, ProjectsH1, ProjectsWrapper,
ProjectsCard, ProjectsIcon, ProjectsH2, ProjectsP, InfoContainer, InfoWrapper, 
InfoRow, Column1, Column2, TextWrapper, TopLine, Heading, Subtitle, BtnWrap, ImgWrap, Img, ParticleWrapper, BuiltWith, TextWrapper2, Subtitle2, ProjectsCard2, TopLine2, MidLine, LinkWrapper, BadgeItem, BadgeIcon, BadgeWrapper } from './ProjectsElements';
import Icon1 from '../../images/ezpath_feature.png'
import Icon2 from '../../images/push_feature.png'
import Icon3 from '../../images/figure_logo.png'
import { ButtonB, ButtonR } from '../ButtonElement';
import Particles from 'react-particles-js';

const Projects = ({lightBg, id, imgStart, topLine, lightText,
    headline, darkText, description,
     buttonLabel, img, alt,
   primary, dark, dark2}) => {
    return (
        <>
        <InfoContainer lightBg={lightBg} id={id}>

        <ParticleWrapper>

        <Particles
            params={{
                'interactivity': {
                    'detect_on': 'window',
                    'events': {
                        'onhover': {
                            'enable': true,
                            
                        }
                    }
                }
            }}
        />

        </ParticleWrapper>

            <InfoWrapper>
                <InfoRow imgStart={imgStart}>
                    <Column1>

                        <ProjectsWrapper>
                            <ProjectsCard2 to={{pathname: "https://play.google.com/store/apps/details?id=com.crosie.ezpath2"}} target="_blank">
                                <ProjectsIcon src={Icon1}/>
                            </ProjectsCard2>
                            
                            <TextWrapper2>
                                <TopLine2>EZPath: Automatic Errands Pathing</TopLine2>
                                <MidLine>Kotlin, XML, Google APIs, Retrofit, Dagger Hilt, Coroutines</MidLine>
                                <Subtitle2>
                                    An app that allows you to enter your errands in a to-do list format, and automatically generate the most efficient path to fulfill every task.
                                </Subtitle2>

                                <LinkWrapper>
                                <BadgeWrapper to={{pathname: "https://play.google.com/store/apps/details?id=com.crosie.ezpath2"}} target="_blank">
                                <BadgeIcon alt="Get it on Google Play "src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"/>
                                </BadgeWrapper>
                                    
                              
        
                                <BtnWrap>
                                <ButtonB to={{pathname: "https://github.com/niloc78/EzPath3"}} target="_blank"
                                primary={primary ? 1 : 0}
                                dark={dark ? 1 : 0}
                                dark2={dark2 ? 1 : 0}
                                >View on GitHub</ButtonB>
                                </BtnWrap>
        
                                </LinkWrapper>

                            </TextWrapper2>

                            <TextWrapper2>

                                    <TopLine2>PUSH | SOS, Mass Text Friends and Family</TopLine2>
                                    <MidLine>Kotlin, Kotlin Coroutines, XML, Room </MidLine>
                                <Subtitle2>
                                    
                                        An emergency SOS app that allows users to periodically send SMS messages to imported contacts with the push of a button including optional location updates.
            
                                </Subtitle2>

                                <LinkWrapper>
                                <BadgeWrapper to={{pathname: "https://play.google.com/store/apps/details?id=com.crosie.push"}} target="_blank">
                                <BadgeIcon alt="Get it on Google Play "src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"/>
                                 </BadgeWrapper>
        
                                <BtnWrap>
                                <ButtonB to={{pathname: "https://github.com/niloc78/PUSH-debug"}} target="_blank"
                                primary={primary ? 1 : 0}
                                dark={dark ? 1 : 0}
                                dark2={dark2 ? 1 : 0}
                                >View on GitHub</ButtonB>
                                </BtnWrap>
        
                                </LinkWrapper>
                                
                            </TextWrapper2>

                            <ProjectsCard to={{pathname: "https://play.google.com/store/apps/details?id=com.crosie.push"}} target="_blank">
                                <ProjectsIcon src={Icon2}/>
                            </ProjectsCard>


                            <ProjectsCard2 to={{pathname: "https://github.com/niloc78/Figure"}} target="_blank">
                                <ProjectsIcon src={Icon3}/>
                            </ProjectsCard2>

                            <TextWrapper2>
                                <TopLine2>Figure | Meal Decider</TopLine2>
                                <MidLine>Java, Javascript, HTML, MongoDB, XML, REST APIs, Google APIs, Android Volley</MidLine>

                            <Subtitle2>
                         
                                An all in one meal decider app. Decide what to eat today by searching recipes based on ingredients in your pantry or parsing restaurant menus in your area.
                                        
                            </Subtitle2>

                            <LinkWrapper>
                                
                                <BtnWrap>
                                <ButtonB to={{pathname: "https://github.com/niloc78/Figure"}} target="_blank"
                                primary={primary ? 1 : 0}
                                dark={dark ? 1 : 0}
                                dark2={dark2 ? 1 : 0}
                                >View on GitHub</ButtonB>
                                </BtnWrap>
        
                                </LinkWrapper>
                                
                            </TextWrapper2>
                        </ProjectsWrapper>
                        

                    </Column1>

                    <Column2>
                    <TextWrapper>
                            <TopLine>{topLine}</TopLine>
                            <Heading lightText={lightText}>{headline}</Heading>
                            <Subtitle darkText={darkText}>{description}</Subtitle>
                            <BtnWrap>
                                <ButtonR to={{pathname: "https://github.com/niloc78"}} target="_blank"
                                primary={primary ? 1 : 0}
                                dark={dark ? 1 : 0}
                                dark2={dark2 ? 1 : 0}
                                >{buttonLabel}</ButtonR>
                            </BtnWrap>
                        </TextWrapper>

                        <ImgWrap>
                            <Img src={img} alt={alt}/>
                        </ImgWrap>
                    </Column2>

                </InfoRow>
                
            </InfoWrapper>


        
        </InfoContainer> 
        
        </>
    );
};

export default Projects;
