import React, {useState} from 'react';
import { HeroContainer, HeroBg, HeroContent, HeroH1, HeroBtnWrapper,
ArrowForward, ArrowRight, Button } from './HeroElements';
import Particles from 'react-particles-js';
import { ParticleWrapper } from '../Projects/ProjectsElements';
const HeroSection = () => {
    const [hover, setHover] = useState(false);
    const onHover = () => {
        setHover(!hover);
    };
    return (
        <HeroContainer id="home">
            <HeroBg>
            </HeroBg>
            <HeroContent>
                <HeroH1>Hello, I'm Colin Ruan. <br/> I'm an Android Developer.</HeroH1>
                <HeroBtnWrapper>
                    <Button to="about" onMouseEnter={onHover}
                     onMouseLeave={onHover}
                     primary='false'
                     dark='false'
                     smooth={true}
                     duration={500}
                     spy={true}
                     exact="true"
                     offset={-80}>
                        View my work {hover ? <ArrowForward/> : <ArrowRight/>}
                    </Button>
                </HeroBtnWrapper>
            </HeroContent>

            <ParticleWrapper>
                <Particles
                    params={{
                        'interactivity': {
                            'detect_on': 'window',
                            'events': {
                                'onhover': {
                                    'enable': true,
                                    
                                }
                            }
                        }
                    }}
                />
            </ParticleWrapper>
            
        </HeroContainer>
        
    );
};

export default HeroSection;
