import styled from 'styled-components';
import {Link} from 'react-scroll';
import {Link as LinkR} from 'react-router-dom';

export const Button = styled(Link)`
    border-radius: 2px;
    background: ${({primary}) => (primary ? 'transparent' : 'transparent')};
    white-space: nowrap;
    padding: ${({big}) => (big ? '14px 48px' : '12px 30px')};
    color: ${({dark}) => (dark ? '#0d0d0d' : '#fff')};
    font-size: ${({fontBig}) => (fontBig ? '20px' : '16px')};
    outline: none;
    border: 1px solid ${({dark}) => (dark ? '#0d0d0d' : '#fff')};
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.2s ease-in-out;

    &:hover {
        transition: all 0.2s ease-in-out;
        background: ${({primary}) => (primary ? '#fff' : '#baabcf')};
        color: ${({dark}) => (dark ? '#fff' : '#fff')};
        border: 1px solid ${({dark}) => (dark ? '#fff' : '#fff')};
    }
`;

export const ButtonR = styled(LinkR)`
    border-radius: 2px;
    background: ${({primary}) => (primary ? 'transparent' : 'transparent')};
    white-space: nowrap;
    padding: ${({big}) => (big ? '14px 48px' : '12px 30px')};
    color: ${({dark}) => (dark ? '#0d0d0d' : '#fff')};
    font-size: ${({fontBig}) => (fontBig ? '20px' : '16px')};
    outline: none;
    border: 1px solid ${({dark}) => (dark ? '#0d0d0d' : '#fff')};
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
    text-decoration: none;
    transition: all 0.2s ease-in-out;

    &:hover {
        transition: all 0.2s ease-in-out;
        background: ${({primary}) => (primary ? '#fff' : '#baabcf')};
        color: ${({dark}) => (dark ? '#fff' : '#fff')};
        border: 1px solid ${({dark}) => (dark ? '#fff' : '#fff')};
    }
`;

export const ButtonB = styled(LinkR)`
    border-radius: 2px;
    background: ${({primary}) => (primary ? 'transparent' : 'transparent')};
    white-space: nowrap;
    padding: ${({big}) => (big ? '14px 48px' : '10px 16px')};
    color: ${({dark}) => (dark ? '#0d0d0d' : '#fff')};
    font-size: ${({fontBig}) => (fontBig ? '16px' : '10px')};
    outline: none;
    border: 1px solid ${({dark}) => (dark ? '#0d0d0d' : '#fff')};
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
    text-decoration: none;
    transition: all 0.2s ease-in-out;

    &:hover {
        transition: all 0.2s ease-in-out;
        background: ${({primary}) => (primary ? '#fff' : '#baabcf')};
        color: ${({dark}) => (dark ? '#fff' : '#fff')};
        border: 1px solid ${({dark}) => (dark ? '#fff' : '#fff')};
    }
    @media screen and (max-width: 480px) {
        padding: ${({big}) => (big ? '14px 48px' : '5px 5px')};
        font-size: ${({fontBig}) => (fontBig ? '16px' : '5px')};
    }
`;

